<!--
 * @Descripttion :评分规则手机端
 * @version      :
 * @Author       : ml
 * @Date         : 2020-03-25 10:11:17
 * @LastEditors  : min
 * @LastEditTime : 2023-07-31 18:19:19
 -->
<template>
  <div>
    <van-popup v-model="showExplain" position="bottom" closeable>
      <template v-if="indexExplain===0">
        <div class="ml_popu_header">
          <h1>外观评分规则</h1>
        </div>
        <van-row class="ml_popu_content">
          <van-col span="8" v-for="(item,index) in wgExplain" :key="index">
            <div class="ml_popu_explain_text">
              <h3>{{item.value}}</h3>
            </div>
            <div class="ml_popu_explain_value">{{item.text}}</div>
          </van-col>
        </van-row>
      </template>

      <template v-if="indexExplain===1">
        <div class="ml_popu_header">
          <h1>骨架评级规则</h1>
        </div>
        <van-row class="ml_popu_content">
          <van-col span="24" class="ml_popu_content_position" v-for="(item,index) in gjExplain" :key="index">
            <div class="ml_popu_item_icon">{{item.value}}</div>
            <div class="ml_popu_item_text">{{item.text}}</div>
          </van-col>
        </van-row>
      </template>

      <template v-if="indexExplain===2">
        <div class="ml_popu_header">
          <h1>内饰评分规则</h1>
        </div>
        <van-row class="ml_popu_content">
          <van-col span="8" v-for="(item,index) in nsExplain" :key="index">
            <div class="ml_popu_explain_text">
              <h3>{{item.value}}</h3>
            </div>
            <div class="ml_popu_explain_value">{{item.text}}</div>
          </van-col>
        </van-row>
      </template>

      <template v-if="indexExplain===3">
        <div class="ml_popu_header">
          <h1>综合评级规则</h1>
        </div>
        <van-row class="ml_popu_content">
          <van-col span="24" class="ml_popu_content_position" v-for="(item,index) in zhExplain" :key="index">
            <div class="ml_popu_item_icon">{{item.value}}</div>
            <div class="ml_popu_item_text">{{item.text}}</div>
          </van-col>
        </van-row>
      </template>

    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showExplain: false, //  是否弹窗
      indexExplain: 0, //  内容下标
      wgExplain: [
        {
          text: '外观准新',
          value: '95'
        },
        {
          text: '外观较好',
          value: '90/80'
        },
        {
          text: '外观一般',
          value: '70/60/50'
        },
        {
          text: '外观较差',
          value: '40/30'
        },
        {
          text: '外观极差',
          value: '25'
        }
      ],
      gjExplain: [
        {
          text: '车辆结构件与加强件均无因事故造成的损伤',
          value: 'A'
        },
        {
          text:
            '车辆结构件无因事故造成的损伤，车辆加强件有因事故造成的轻微损伤',
          value: 'B'
        },
        {
          text:
            '车辆结构件无因事故造成的损伤，车辆加强件有因事故造成的严重损伤',
          value: 'C'
        },
        {
          text: '车辆结构件有因事故造成的损伤',
          value: 'D'
        }
      ],
      nsExplain: [
        {
          text: '内饰准新',
          value: '95'
        },
        {
          text: '内饰较好',
          value: '90/80'
        },
        {
          text: '内饰一般',
          value: '70/60/50'
        },
        {
          text: '内饰较差',
          value: '40/30'
        },
        {
          text: '内饰极差',
          value: '25'
        }
      ],
      zhExplain: [
        {
          text: '骨架等级≥A，外观评分≥95，内饰评分≥95，不可出现更换，切割，锈蚀，泥沙，水渍，部件缺失，霉斑，烧烫和机电模块损伤',
          value: 'A'
        },
        {
          text: '骨架等级≥A，外观评分≥85，内饰评分≥85，不可出现切割，泥沙，水渍，霉斑，锈蚀等损伤',
          value: 'B'
        },
        {
          text: '骨架等级≥B，外观评分≥75，内饰评分≥75，不可出现切割，泥沙，水渍，霉斑等损伤',
          value: 'C'
        },
        {
          text: '骨架等级≥C，外观评分≥70，内饰评分≥70',
          value: 'D'
        },
        {
          text: '骨架等级≥D，外观评分≥65，内饰评分≥65',
          value: 'E'
        },
        {
          text: '骨架等级≥D，外观评分≥60，内饰评分≥60',
          value: 'F'
        },
        {
          text: '骨架等级≥D，外观评分<60，内饰评分<60',
          value: 'G'
        }
      ]
    }
  },
  methods: {
    showExplainPopu(index) {
      this.showExplain = true
      this.indexExplain = index
    }
  }
}
</script>
<style lang="scss" scoped>
.ml_popu_header {
  text-align: center;

  border-bottom: 1px solid #f1f1f1;
  h1 {
    padding: 12px 0;
    font-size: 16px;
  }
}

.ml_popu_content {
  padding: 10px 0;
}
.ml_popu_explain_text {
  color: #ff7e0a;

  padding: 15px 0 0 0;
  text-align: center;
  h3 {
    font-size: 20px;
  }
}
.ml_popu_explain_value {
  color: #a19f9e;
  padding: 5px 0;
  text-align: center;
}

.ml_popu_content_position {
  position: relative;
  .ml_popu_item_icon {
    position: absolute;
    left: 5px;
    top: 50%;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 100%;
    background-color: #fff5ee;
    color: #ff9637;
    font-size: 22px;
    padding: 10px;
    transform: translateY(-50%);
  }
  .ml_popu_item_text {
    color: #878482;
    padding: 10px 0;
    padding-left: 50px;
    font-size: 16px;
  }
}
</style>
