<template>
  <div class="ml_detection_report">
    <div class="ml_car_detection_info">
      <!-- 综合 -->
      <div class="ml_detection_line"></div>
      <div class="ml_detection_list">
        <!-- 标题 -->
        <div class="ml_detection_header ml_detection_header_line">
          <van-row>
            <van-col span="8" class="ml_header_title">综合
              <span class="ml_detection_header_tip">
                {{carlevel}}
              </span>
              <img @click="showExplainPopu(3)" class="ml_icon_img" src="../../assets/008.png" />
            </van-col>
            <van-col span="16" class="ml_detection_header_tip">
              <!-- {{wgDetection.score_remark}} -->
            </van-col>
          </van-row>
        </div>
      </div>
      <!-- 外观 -->
      <div class="ml_detection_line" id="Element001"></div>
      <template v-if="wgDetection">
        <div class="ml_detection_list" id="wgTab">
          <!-- 标题 -->
          <div class="ml_detection_header ml_detection_header_line">
            <van-row>
              <van-col span="8" class="ml_header_title">外观
                <span class="ml_detection_header_tip">
                  {{wgDetection.real_score}}
                </span>
                <img @click="showExplainPopu(0)" class="ml_icon_img" src="../../assets/008.png" />
              </van-col>
              <van-col span="16" class="ml_detection_header_tip">
                {{wgDetection.score_remark}}
              </van-col>
            </van-row>
          </div>
          <!-- 颜色状态说明 -->
          <div class="ml_detection_color_list">
            <div class="ml_detection_color_list_item" v-for="(item,index) in wgDetection.crlist" :key="index">
              <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
              {{item.crname}}
            </div>
          </div>
          <!-- 损伤点位 -->
          <div class="ml_detection_img">
            <img src="../../assets/001.png" @click.stop />
            <template v-for="(item,index) in wgDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(wgDetection.clist,item.listimg[0].id)" :key="index"></div>
            </template>
          </div>
        </div>
        <!-- 异常项 -->
        <van-collapse v-model="activeNames1" v-if="wgDetection.e_count>0">
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{wgDetection.e_count}}项异常</span>
              <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <van-cell-group>
              <van-cell :title="item.name" v-for="(item,index) in wgDetection.clist" :key="index">
                <template #label>
                  <van-row gutter="5">
                    <van-col span="6" v-for="items in item.listimg" :key="items.id">
                      <van-image v-if="items.defect_image" :src="items.defect_image +'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" @click="viewDetection(wgDetection.clist,items.id)">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </van-col>
                  </van-row>
                  <div>
                    <van-tag plain v-for="(items,indexs) in item.defectname" :key="indexs"> {{items}}</van-tag>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames1" v-else>
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{wgDetection.e_count}}项异常</span>
              <img src="../../assets/005.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <div slot="right-icon"></div>
          </van-collapse-item>
        </van-collapse>
      </template>

      <!-- 骨架 -->
      <div class="ml_detection_line" id="Element002"></div>
      <template v-if="gjDetection">
        <div class="ml_detection_list" id="gjTab">
          <!-- 标题 -->
          <div class="ml_detection_header ml_detection_header_line">
            <van-row>
              <van-col span="8" class="ml_header_title">骨架
                <span class="ml_detection_header_tip">
                  {{gjDetection.real_score}}
                </span>
                <img @click="showExplainPopu(1)" class="ml_icon_img" src="../../assets/008.png" />
              </van-col>
              <van-col span="16" class="ml_detection_header_tip">
                {{gjDetection.score_remark}}
              </van-col>
            </van-row>
          </div>
          <!-- 颜色状态说明 -->
          <div class="ml_detection_color_list">
            <div class="ml_detection_color_list_item" v-for="(item,index) in gjDetection.crlist" :key="index">
              <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
              {{item.crname}}
            </div>
          </div>
          <!-- 损伤点位 -->
          <div class="ml_detection_img">
            <img src="../../assets/002.png" @click.stop="" />
            <template v-for="(item,index) in gjDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(gjDetection.clist,item.listimg[0].id)" :key="index"></div>
            </template>
          </div>
        </div>
        <!-- 异常项 -->
        <van-collapse v-model="activeNames2" v-if="gjDetection.e_count>0">
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{gjDetection.e_count}}项异常</span>
              <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <van-cell-group>
              <van-cell :title="item.name" v-for="(item,index) in gjDetection.clist" :key="index">
                <template #label>
                  <van-row gutter="5">
                    <van-col span="6" v-for="items in item.listimg" :key="items.id">
                      <van-image v-if="items.defect_image" :src="items.defect_image +'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" @click="viewDetection(gjDetection.clist,items.id)">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </van-col>
                  </van-row>
                  <div>
                    <van-tag plain :key="indexs" v-for="(items,indexs) in item.defectname"> {{items}}</van-tag>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames2" v-else>
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{gjDetection.e_count}}项异常</span>
              <img src="../../assets/005.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <div slot="right-icon"></div>
          </van-collapse-item>
        </van-collapse>
      </template>

      <!-- 内饰 -->
      <div class="ml_detection_line" id="Element003"></div>
      <template v-if="nsDetection">
        <div class="ml_detection_list" id="nsTab">
          <!-- 标题 -->
          <div class="ml_detection_header ml_detection_header_line">
            <van-row>
              <van-col span="8" class="ml_header_title">内饰
                <span class="ml_detection_header_tip">
                  {{nsDetection.real_score}}
                </span>
                <img @click="showExplainPopu(2)" class="ml_icon_img" src="../../assets/008.png" />
              </van-col>
              <van-col span="16" class="ml_detection_header_tip">
                {{nsDetection.score_remark}}
              </van-col>
            </van-row>
          </div>
          <!-- 颜色状态说明 -->
          <div class="ml_detection_color_list">
            <div class="ml_detection_color_list_item" v-for="(item,index) in nsDetection.crlist" :key="index">
              <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
              {{item.crname}}
            </div>
          </div>
          <!-- 损伤点位 -->
          <div class="ml_detection_img">
            <img src="../../assets/003.png" @click.stop="" />
            <template v-for="(item,index) in nsDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(nsDetection.clist,item.listimg[0].id)" :key="index"></div>
            </template>
          </div>
        </div>
        <!-- 异常项 -->
        <van-collapse v-model="activeNames3" v-if="nsDetection.e_count>0">
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{nsDetection.e_count}}项异常</span>
              <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <van-cell-group>
              <van-cell :title="item.name" v-for="(item,index) in nsDetection.clist" :key="index">
                <template #label>
                  <van-row gutter="5">
                    <van-col span="6" v-for="items in item.listimg" :key="items.id">
                      <van-image v-if="items.defect_image" :src="items.defect_image +'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" @click="viewDetection(nsDetection.clist,items.id)">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </van-col>
                  </van-row>
                  <div>
                    <van-tag plain v-for="(items,indexs) in item.defectname" :key="indexs"> {{items}}</van-tag>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames3" v-else>
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{nsDetection.e_count}}项异常</span>
              <img src="../../assets/005.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <div slot="right-icon"></div>
          </van-collapse-item>
        </van-collapse>
      </template>

      <!-- 机电 -->
      <div class="ml_detection_line" id="Element004"></div>
      <template v-if="jdDetection">
        <div class="ml_detection_list" id="jsTab">
          <div class="ml_detection_header">
            <van-row>
              <van-col span="24" class="ml_header_title">机电
              </van-col>
            </van-row>
          </div>
        </div>
        <van-collapse v-model="activeNames4" v-if="jdDetection.e_count>0">
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{jdDetection.e_count}}项异常</span>
              <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <template v-for="(item,index) in jdDetection.clist">
              <van-cell v-if="item.is_check==1" :key="index">
                <!-- 使用 title 插槽来自定义标题 -->
                <template slot="title">
                  <van-row>
                    <van-col span="8">
                      {{item.name}}
                    </van-col>
                    <van-col span="16">
                      <div class="ml_detection_tag">
                        <van-tag plain v-for="(items,indexs) in item.dlist" :key="indexs">{{items.name}}</van-tag>
                      </div>
                    </van-col>
                    <van-col span="24">
                      <template v-for="items in item.defectlist">
                        <van-image :src="itemss" v-for="(itemss,indexss) in items.defectimage" :key="indexss" @click="viewJDDetection(item,itemss)" width="100" height="80" style="padding:0 10px 10px 0;">
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                          <template v-slot:error>加载失败</template>
                        </van-image>
                      </template>
                    </van-col>
                  </van-row>
                </template>
              </van-cell>
            </template>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames4" v-else>
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{jdDetection.e_count}}项异常</span>
              <img src="../../assets/005.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <div slot="right-icon"></div>
          </van-collapse-item>
        </van-collapse>

      </template>

      <!-- 水渍残留点 -->
      <div class="ml_detection_line" id="Element005"></div>
      <template v-if="szDetection">
        <div class="ml_detection_list" id="szclTab">
          <!-- 标题 -->
          <div class="ml_detection_header">
            <van-row>
              <van-col span="24" class="ml_header_title" style="color:#323233;font-weight:bold;">水渍残留点
              </van-col>
            </van-row>
          </div>
        </div>
        <div class="boche134" v-if="iscarwaterin==='1'||iscarwaterin==='0'">
          车辆是否曾进水(漏水/涉水/水泡)：{{iscarwaterin==1?'是':'否'}}
        </div>
        <!-- 异常项 -->
        <van-collapse v-model="activeNames5" v-if="szDetection.e_count>0">
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{szDetection.e_count}}项异常</span>
              <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <van-cell-group>
              <van-cell :title="item.name" v-for="(item,index) in szDetection.clist" :key="index">
                <template #label>
                  <van-row gutter="5">
                    <van-col span="6" v-for="items in item.listimg" :key="items.id">
                      <van-image v-if="items.defect_image" :src="items.defect_image +'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" @click="viewDetection(szDetection.clist,items.id)">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                        <template v-slot:error>加载失败</template>
                      </van-image>
                    </van-col>
                  </van-row>
                  <div>
                    <van-tag plain v-for="(items,indexs) in item.defectname" :key="indexs"> {{items}}</van-tag>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>
        <van-collapse v-model="activeNames5" v-else>
          <van-collapse-item>
            <div slot="title">
              <span class="custom-title">{{szDetection.e_count}}项异常</span>
              <img src="../../assets/005.png" style="display:inline-block;width:12px;margin-left:10px;" />
            </div>
            <div slot="right-icon"></div>
          </van-collapse-item>
        </van-collapse>
      </template>

      <div class="ml_detection_line"></div>
      <van-cell size="large" :border="false">
        <template slot="title">
          <span class="custom-title-car">综合描述</span>
        </template>
        <template slot="label">
          <div class="custom-label" style="white-space: pre-wrap;line-height:20px">{{detectionremark}}</div>
        </template>
      </van-cell>

      <div class="ml_detection_line"></div>
    </div>

    <!-- 检测图片预览 -->
    <van-popup v-model="viewImgShow">
      <div style="width:100%;" v-if="viewImgShow">
        <van-swipe :show-indicators="false" :loop="true" :initial-swipe="viewImgIndex" @change="changeSwipe">
          <van-swipe-item v-for="(item,index) in viewImgs" :key="index">
            <div style="position:relative;">
              <van-image :src="item.defect_image" width="100%" style="min-height: 100px;">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error>加载失败</template>
              </van-image>
              <template v-for="(items,indexs) in item.dlist">
                <template v-if="items.x>0&&items.y>0">
                  <template v-if="items.x>0.5">
                    <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                      <div class="ml_dot_color" :style="{'background-color':items.crvalue}">
                        <div class="ml_dot_detection_text">
                          <span class="ml_dot_text">
                            {{items.name}}
                          </span>
                          <span class="ml_dot_triangle"></span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                      <div class="ml_dot_color" :style="{'background-color':items.crvalue}">
                        <div class="ml_dot_detection_text">
                          <span class="ml_dot_triangle"></span>
                          <span class="ml_dot_text">
                            {{items.name}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="ml_detection_name">
              {{viewImgs[viewImgIndex].title}} ({{viewImgs[viewImgIndex].index}}/{{viewImgs[viewImgIndex].count}})
              <div class="ml_detection_name_checkbox">
                <van-checkbox v-model="zoomChecked" icon-size="16px" @change="changeZoomCheckbox">关文字</van-checkbox>
              </div>
            </div>
          </template>
        </van-swipe>
      </div>
    </van-popup>
    <!-- 检测图片预览-放大 -->
    <van-image-preview v-model="viewZoomImgShow" :images="zoomImages" :start-position="viewImgIndex" :showIndex="false" @change="onChangeZoomView">
      <template v-slot:cover>
        <div class="ml_detection_name">
          {{viewImgs[viewImgIndex].title}} ({{viewImgs[viewImgIndex].index}}/{{viewImgs[viewImgIndex].count}})
          <div class="ml_detection_name_checkbox">
            <van-checkbox v-model="zoomChecked" icon-size="16px" @change="changeZoomCheckbox"><span style="color:#fff;">关文字</span></van-checkbox>
          </div>
        </div>
      </template>
    </van-image-preview>

    <!-- 机电检测图片预览 -->
    <van-popup v-model="viewJDImgShow" @click="viewJDImgShow=false">
      <div style="width:100%;">
        <van-swipe :show-indicators="false" :loop="true" :initial-swipe="viewJDImgIndex">
          <van-swipe-item v-for="(item,index) in viewJDImgs" :key="index">
            <van-image :src="item.url+'?x-oss-process=image/resize,w_1280,h_960/quality,q_90'" width="100%" style="min-height: 100px;">
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
              <template v-slot:error>加载失败</template>
            </van-image>
            <div class="ml_detection_name">
              {{item.name}}
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </van-popup>

    <componentRule ref="componentRule"></componentRule>

  </div>
</template>

<script>

import componentRule from '@/components/component_mobile/component_rule_mobile'

export default {
  components: {
    componentRule
  },
  props: {
    reportData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      detectionremark: '',
      activeNames1: [],
      activeNames2: [],
      activeNames3: [],
      activeNames4: [],
      activeNames5: [],

      wgDetection: null,
      gjDetection: null,
      nsDetection: null,
      jdDetection: null,
      szDetection: null,

      viewImgShow: false,
      viewImgIndex: 0,
      viewImgs: [],
      viewZoomImgs: [],
      zoomChecked: false, //  开关文字状态
      viewZoomImgShow: false, //  关闭文字、图片放大
      zoomImages: [], //  关闭文字图片放大

      viewJDImgShow: false,
      viewJDImgIndex: 0,
      viewJDImgs: [],
      iscarwaterin: '', //车辆是否曾进水(漏水/涉水/水泡)
    }
  },
  mounted() {
    const that = this

    that.detectionremark = that.reportData.detectionremark
    that.carlevel = that.reportData.carlevel || '-'
    that.iscarwaterin = that.reportData.iscarwaterin
    that.reportData.data.forEach(element => {
      switch (element.part_code) {
        case 'P01':
          // 骨架
          that.gjDetection = element
          break
        case 'P02':
          // 外观
          that.wgDetection = element
          break
        case 'P03':
          // 内饰
          that.nsDetection = element
          break
        case 'M013':
          // 机电
          that.jdDetection = element
          break
        case '-1':
          // 水渍残留点
          that.szDetection = element
          break
      }
    })
  },
  methods: {
    showExplainPopu(index) {
      // 提示文字
      this.$refs.componentRule.showExplainPopu(index)
    },
    changeZoomCheckbox(e) {
      // 查看/关闭文字
      this.zoomChecked = e
      if (e) {
        this.viewZoomImgShow = true
        this.viewImgShow = false
      } else {
        this.viewZoomImgShow = false
        this.viewImgShow = true
      }
    },
    changeSwipe(i) {
      // 缺陷轮播
      this.$nextTick(() => {
        this.viewImgIndex = i
      })
    },
    onChangeZoomView(i) {
      // 放大缩小图片切换事件
      this.$nextTick(() => {
        this.viewImgIndex = i
      })
    },
    viewDetection(obj, id) {
      this.viewImgs = []
      let _viewImgs = []
      let _viewImgsObj = []
      let _viewImgIndex = 0

      obj.forEach(element => {
        element.listimg.forEach((item, index) => {
          // 判断是否上传图片
          if (item.defect_image) {
            if (item.id === id) {
              _viewImgIndex = _viewImgsObj.length
            }
            _viewImgs.push(item.defect_image +
              '?x-oss-process=image/resize,w_1280,h_960/quality,q_90')

            _viewImgsObj.push({
              title: element.name,
              defect_image: item.defect_image +
                '?x-oss-process=image/resize,w_1280,h_960/quality,q_90',
              dlist: item.dlist,
              index: index + 1,
              count: element.listimg.length
            })
          }
        })
      })
      if (_viewImgs && _viewImgs.length) {
        this.zoomImages = _viewImgs
        this.viewImgs = _viewImgsObj
        this.viewImgIndex = _viewImgIndex
        this.viewImgShow = true
        this.zoomChecked = false
      }
    },
    viewJDDetection(obj, url) {
      const that = this
      that.viewJDImgs = []
      obj.defectlist.forEach(element => {
        element.defectimage.forEach((item, index) => {
          if (item === url) {
            that.viewJDImgIndex = that.viewJDImgs.length
          }
          that.viewJDImgs.push({
            name:
              obj.name +
              element.defectname +
              '图片' +
              (index + 1),
            url: item
          })
        })
      })

      that.viewJDImgShow = true
    }
  }
}
</script>

<style lang="scss">
.ml_detection_report {
  .ml_car_detection_info {
    .ml_detection_mark {
      padding: 15px;

      .ml_detection_mark_content {
        padding: 16px;
        color: #9a9a9a;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
      }
    }

    .ml_detection_line {
      border-bottom: 10px solid #f2f2f2;
    }

    .ml_detection_list {
      .ml_detection_header_line {
        border-bottom: 1px solid #f1f1f1;
      }
      .ml_detection_header {
        padding: 15px 0 15px 20px;

        line-height: 20px;

        .ml_header_title {
          font-size: 16px;

          .ml_icon_img {
            display: inline-block;
            width: 12px;
            vertical-align: middle;
            margin-top: -3px;
            margin-left: 5px;
          }
        }

        .ml_detection_header_tip {
          color: #ff7901;
          font-size: 14px;
        }
      }

      .ml_detection_img {
        margin: 10px;
        position: relative;

        img {
          width: 100%;
        }

        .ml_circle {
          width: 12px;
          height: 12px;
          text-align: center;
          border-radius: 100%;
          position: absolute;
          color: #fff;
        }
      }

      .ml_detection_color_list {
        padding: 20px 0 10px 0;
        display: flex;
        color: #7d7d7d;

        .ml_detection_color_list_item {
          flex: 1;
          text-align: center;

          .ml_color_circle {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            margin-right: 10px;
            display: inline-block;
          }
        }
      }
    }

    // .van-hairline--top-bottom::after,
    // .van-hairline-unset--top-bottom::after {
    //   //   border-width: 0;
    // }

    .custom-title {
      color: #6c6c6c;
    }
  }

  .van-swipe-item {
    .ml_dot_detection {
      position: absolute;
      z-index: 9999;
      margin-top: -3px;
      margin-left: -3px;

      .ml_dot_detection_text {
        position: absolute;
        left: 10px;
        margin-top: -7px;
      }

      .ml_dot_color {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #797979;
      }

      .ml_dot_triangle {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid rgba(0, 0, 0, 0.8);
          border-bottom: 10px solid transparent;
          display: inline-block;
        }
      }

      .ml_dot_text {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        height: 20px;
        line-height: 20px;
        display: inline-block;
        white-space: nowrap;
        padding: 0 6px 0 2px;
        margin-left: 10px;
        font-size: 12px;
      }
    }

    .ml_dot_detection1 {
      position: absolute;
      z-index: 9999;
      margin-top: -3px;
      margin-left: -3px;

      .ml_dot_detection_text {
        position: absolute;
        right: 20px;
        margin-top: -7px;
      }

      .ml_dot_color {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: #797979;
      }

      .ml_dot_triangle {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-left: 10px solid rgba(0, 0, 0, 0.8);
          border-bottom: 10px solid transparent;
          display: inline-block;
        }
      }

      .ml_dot_text {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        height: 20px;
        line-height: 20px;
        display: inline-block;
        white-space: nowrap;
        padding: 0 2px 0 5px;
        font-size: 12px;
      }
    }
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 80px 5px 10px;
    position: relative;
    .ml_detection_name_checkbox {
      position: absolute;
      right: 10px;
      top: 5px;
      line-height: 20px;
    }
  }
  .van-cell:not(:last-child)::after {
    right: 16px;
  }

  .van-collapse-item__wrapper .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0 0;
  }

  .ml_img_cover_circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .boche134 {
    position: relative;
    padding: 12px 16px;
  }
  .boche134::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border-top: 1px solid #ebedf0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
</style>
